import React from 'react';
import {
  Button,
  ExpoImage,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { BlurView } from 'expo-blur';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Modal, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as NewXANOAPIApi from '../apis/NewXANOAPIApi.js';
import * as TagsAPIApi from '../apis/TagsAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import formatGoToClickedTagPageScreen from '../global-functions/formatGoToClickedTagPageScreen';
import navigateToCompleteOnboarding from '../global-functions/navigateToCompleteOnboarding';
import pushToHistory from '../global-functions/pushToHistory';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { tagID: 688 };

const UnverifiedTagPreviewScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const subtractPadding = (width, padding) => {
    return (width || 0) - (padding || 0) * 2;
  };
  const tagsAPIDeleteUnverifiedTagDELETE =
    TagsAPIApi.useDeleteUnverifiedTagDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        pushToHistory(
          Variables,
          setGlobalVariableValue,
          segment,
          'UnverifiedTagPreviewScreen',
          formatGoToClickedTagPageScreen(
            props.route?.params?.tagID ?? defaultProps.tagID
          )
        );
        const meResult = (await NewXANOAPIApi.meGET(Constants))?.json;
        if (meResult?.code === 'ERROR_CODE_UNAUTHORIZED') {
          navigation.navigate('LoginScreen');
        }
        if (
          navigateToCompleteOnboarding(
            navigation,
            setGlobalVariableValue,
            Constants['USER']
          )
        ) {
          navigation.navigate('SignUpScreen', { completeOnBoarding: true });
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.background.base },
        dimensions.width
      )}
    >
      <Surface
        elevation={0}
        {...GlobalStyles.SurfaceStyles(theme)['FloatingBackButtonShadow'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.SurfaceStyles(theme)['FloatingBackButtonShadow'].style,
            { backgroundColor: theme.colors.background.base }
          ),
          dimensions.width
        )}
      >
        <Touchable
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'ProfileStackNavigator',
                params: {
                  screen: 'ProfileScreen',
                  params: { initialTabIndex: 2 },
                },
              });
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors.text.strong}
              name={'AntDesign/leftcircle'}
              size={40}
            />
          </View>
        </Touchable>
      </Surface>

      <View
        style={StyleSheet.applyWidth(
          { alignSelf: 'center', flex: 1, justifyContent: 'flex-end' },
          dimensions.width
        )}
      >
        <TagsAPIApi.FetchGetTagDetailsGET
          tag_id={props.route?.params?.tagID ?? defaultProps.tagID}
        >
          {({ loading, error, data, refetchGetTagDetails }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <SimpleStyleKeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                keyboardShouldPersistTaps={'never'}
                showsVerticalScrollIndicator={true}
                viewIsInsideTabBar={false}
                {...GlobalStyles.SimpleStyleKeyboardAwareScrollViewStyles(
                  theme
                )['ResponsiveKeyboardAwareScrollView 6'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SimpleStyleKeyboardAwareScrollViewStyles(
                      theme
                    )['ResponsiveKeyboardAwareScrollView 6'].style,
                    { justifyContent: 'flex-end', paddingTop: 30 }
                  ),
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderWidth: { minWidth: Breakpoints.Laptop, value: 1 },
                      flex: 1,
                      justifyContent: 'flex-end',
                      marginTop: 40,
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flex: 1,
                        justifyContent: 'flex-end',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['label'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          {
                            color: theme.colors.text.strong,
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 14 },
                              { minWidth: Breakpoints.Tablet, value: 18 },
                            ],
                            marginBottom: 11,
                            marginLeft: 11,
                            marginRight: 11,
                            textAlign: [
                              { minWidth: Breakpoints.Mobile, value: 'center' },
                              { minWidth: Breakpoints.Tablet, value: 'center' },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        "Thanks for submitting. We'll review and if approved you'll see the approved tag on your profile page."
                      }
                    </Text>
                    <Link
                      accessible={true}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              `${fetchData?._tag_retail_details?.product_link}`
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      selectable={false}
                      {...GlobalStyles.LinkStyles(theme)['Link'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.LinkStyles(theme)['Link'].style,
                          {
                            color: theme.colors.text.strong,
                            fontFamily: 'Poppins_400Regular',
                            fontSize: {
                              minWidth: Breakpoints.Tablet,
                              value: 18,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Click for Product Link\n'}
                    />
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              `${fetchData?._tag_retail_details?.product_link}`
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      activeOpacity={0.8}
                      disabledOpacity={0.8}
                      style={StyleSheet.applyWidth(
                        { height: '80%', width: '100%' },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flex: 1,
                            justifyContent: 'flex-end',
                            maxHeight: [
                              { minWidth: Breakpoints.Tablet, value: 800 },
                              { minWidth: Breakpoints.Laptop, value: 600 },
                              { minWidth: Breakpoints.Desktop, value: 800 },
                              { minWidth: Breakpoints.BigScreen, value: 1024 },
                            ],
                            maxWidth: 1024,
                            width: subtractPadding(dimensions.width, 12),
                          },
                          dimensions.width
                        )}
                      >
                        <ExpoImage
                          allowDownscaling={true}
                          cachePolicy={'disk'}
                          contentPosition={'center'}
                          transitionDuration={300}
                          transitionEffect={'cross-dissolve'}
                          transitionTiming={'ease-in-out'}
                          {...GlobalStyles.ExpoImageStyles(theme)[
                            'ResponsiveProductImage 2'
                          ].props}
                          resizeMode={'contain'}
                          source={imageSource(
                            `${
                              (
                                fetchData?._tag_photos &&
                                (fetchData?._tag_photos)[0]
                              )?.tag_photo?.url
                            }`
                          )}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ExpoImageStyles(theme)[
                                'ResponsiveProductImage 2'
                              ].style,
                              {
                                height: '100%',
                                maxHeight: (
                                  fetchData?._tag_photos &&
                                  (fetchData?._tag_photos)[0]
                                )?.tag_photo?.meta?.height,
                                maxWidth: (
                                  fetchData?._tag_photos &&
                                  (fetchData?._tag_photos)[0]
                                )?.tag_photo?.meta?.width,
                                width: '100%',
                              }
                            ),
                            dimensions.width
                          )}
                        />
                      </View>
                    </Touchable>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      { margin: 25 },
                      dimensions.width
                    )}
                  >
                    {/* TagText */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          overflow: 'hidden',
                          paddingBottom: 5,
                          paddingLeft: 5,
                          paddingTop: 3,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1, flexWrap: 'nowrap', maxWidth: 300 },
                          dimensions.width
                        )}
                      >
                        {/* Nickname */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.text.strong,
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                            },
                            dimensions.width
                          )}
                        >
                          {fetchData?.tag_name}
                        </Text>
                        {/* Series and Episode */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.text.strong,
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                            },
                            dimensions.width
                          )}
                        >
                          {fetchData?._tag_content_details?.series_name}{' '}
                          {fetchData?._tag_content_details?.episode}
                        </Text>
                        <Link
                          accessible={true}
                          onPress={() => {
                            const handler = async () => {
                              try {
                                await WebBrowser.openBrowserAsync(
                                  `${fetchData?._tag_retail_details?.product_link}`
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.text.strong,
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              textDecorationLine: 'underline',
                            },
                            dimensions.width
                          )}
                          title={`${fetchData?._tag_retail_details?.product_name}`}
                        />
                        {/* Creative Source */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.text.strong,
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 17,
                            },
                            dimensions.width
                          )}
                        >
                          {fetchData?._tag_retail_details?.retailer}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                {/* HorizontalActions */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['HorizontalActions'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['HorizontalActions'].style,
                      {
                        justifyContent: 'flex-end',
                        marginBottom: 8,
                        marginLeft: 15,
                        marginRight: 15,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <View>
                    {/* DeleteButton */}
                    <IconButton
                      onPress={() => {
                        try {
                          setShowDeleteModal(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      color={theme.colors.text.danger}
                      icon={'Ionicons/trash-outline'}
                      size={40}
                    />
                  </View>
                </View>
              </SimpleStyleKeyboardAwareScrollView>
            );
          }}
        </TagsAPIApi.FetchGetTagDetailsGET>
      </View>
      {/* ConfirmDeleteModal */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'fade'}
        presentationStyle={'formSheet'}
        transparent={true}
        visible={showDeleteModal}
      >
        <BlurView
          experimentalBlurMethod={'none'}
          intensity={50}
          tint={'default'}
          {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
            dimensions.width
          )}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: '"rgba(252, 252, 252, 0.8)"',
                  },
                  {
                    minWidth: Breakpoints.Mobile,
                    value:
                      Platform.OS === 'android'
                        ? palettes.App.White
                        : palettes.App['Semi Transparent BG'],
                  },
                ],
                borderColor: palettes.App['Border Color'],
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 400,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* ModalHeader */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: palettes.App['Border Color'],
                  flexDirection: 'row',
                  padding: 16,
                  paddingBottom: 4,
                },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      alignSelf: 'auto',
                      flex: 1,
                      fontFamily: 'Poppins_700Bold',
                      fontSize: 16,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Are you sure? \n'}
              </Text>
              {/* CloseModalButton */}
              <IconButton
                onPress={() => {
                  try {
                    setShowDeleteModal(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                color={palettes.App.BackgroundPurple}
                icon={'AntDesign/closecircleo'}
                style={StyleSheet.applyWidth(
                  { marginLeft: 16 },
                  dimensions.width
                )}
              />
            </View>
            {/* ModalBody */}
            <View
              style={StyleSheet.applyWidth(
                { flexGrow: 1, flexShrink: 0, padding: 16 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {
                  'You are about to delete this comment. You can not undo this action. Do you want to proceed? '
                }
              </Text>
            </View>
            {/* ModalFooter */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderRadius: 9,
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 16,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginRight: 4 },
                  dimensions.width
                )}
              >
                {/* CancelButton */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setShowDeleteModal(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: '"rgba(0, 0, 0, 0)"',
                        borderColor: theme.colors.branding.primary,
                        borderWidth: 1,
                        color: theme.colors.branding.primary,
                        lineHeight: 22,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginLeft: 4 },
                  dimensions.width
                )}
              >
                {/* ConfirmButton */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setIsDeleting(true);
                        (
                          await tagsAPIDeleteUnverifiedTagDELETE.mutateAsync({
                            tagId:
                              props.route?.params?.tagID ?? defaultProps.tagID,
                          })
                        )?.json;
                        setIsDeleting(false);
                        setShowDeleteModal(false);
                        navigation.navigate('BottomTabNavigator', {
                          screen: 'ProfileStackNavigator',
                          params: {
                            screen: 'ProfileScreen',
                            params: { initialTabIndex: 2 },
                          },
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  disabled={isDeleting}
                  loading={isDeleting}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: palettes.App['Custom Color_11'],
                        lineHeight: 22,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Yes, Delete!'}
                />
              </View>
            </View>
          </View>
        </BlurView>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(UnverifiedTagPreviewScreen);