const initialize_isLikedByUserCache = async (
  Variables,
  setGlobalVariableValue,
  token
) => {
  let ids = await fetch(
    `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/get_liked_tag_ids`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  ).then(res => res.json());

  const isLikedByUserCache = Variables?.isLikedByUserCache || {};
  if (ids && Array.isArray(ids)) {
    for (let id of ids) {
      isLikedByUserCache[id] = true;
    }
  }
  setGlobalVariableValue({
    key: 'isLikedByUserCache',
    value: isLikedByUserCache,
  });
};

export default initialize_isLikedByUserCache;
