import React, { useEffect } from 'react';
import { Appearance } from 'react-native';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const Provider = ({ children }) => {
  const variables = GlobalVariables.useValues();
  // const setGlobalVariableValue = GlobalVariables.useSetValue();

  useEffect(() => {
    if (!variables.COLOR_SCHEME) {
      Appearance.setColorScheme('light');
    } else {
      Appearance.setColorScheme(variables.COLOR_SCHEME);
    }
  }, [variables.COLOR_SCHEME]);

  return children;
};
