import React from 'react';
import {
  Button,
  Checkbox,
  ExpoImage,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleKeyboardAwareScrollView,
  TabView,
  TabViewItem,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { BlurView } from 'expo-blur';
import { ActivityIndicator, Modal, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as NewXANOAPIApi from '../apis/NewXANOAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import * as TagLists from '../custom-files/TagLists';
import navigateToCompleteOnboarding from '../global-functions/navigateToCompleteOnboarding';
import pushToHistory from '../global-functions/pushToHistory';
import toRelativeTime from '../global-functions/toRelativeTime';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { initialTabIndex: 0 };

const ProfileScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [commentIdToDelete, setCommentIdToDelete] = React.useState('');
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [likingCommentId, setLikingCommentId] = React.useState('');
  const [refreshData, setRefreshData] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const newXANOAPIToggleCommentLikePOST =
    NewXANOAPIApi.useToggleCommentLikePOST();
  const newXANOAPIDeleteCommentDELETE = NewXANOAPIApi.useDeleteCommentDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        pushToHistory(
          Variables,
          setGlobalVariableValue,
          segment,
          'ProfileScreen',
          undefined
        );
        const meResult = (await NewXANOAPIApi.meGET(Constants))?.json;
        console.log(meResult);
        if (meResult?.code === 'ERROR_CODE_UNAUTHORIZED') {
          navigation.navigate('LoginScreen');
        }
        setRefreshData(!refreshData);
        if (
          navigateToCompleteOnboarding(
            navigation,
            setGlobalVariableValue,
            Constants['USER']
          )
        ) {
          navigation.navigate('SignUpScreen', { completeOnBoarding: true });
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' } },
        dimensions.width
      )}
    >
      <SimpleStyleKeyboardAwareScrollView
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
        {...GlobalStyles.SimpleStyleKeyboardAwareScrollViewStyles(theme)[
          'ResponsiveKeyboardAwareScrollView 9'
        ].props}
        enableAutomaticScroll={true}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.SimpleStyleKeyboardAwareScrollViewStyles(theme)[
              'ResponsiveKeyboardAwareScrollView 9'
            ].style,
            { alignSelf: { minWidth: Breakpoints.BigScreen, value: 'center' } }
          ),
          dimensions.width
        )}
      >
        {/* Header */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Tablet, value: 'stretch' },
              alignSelf: { minWidth: Breakpoints.Tablet, value: 'center' },
              flexDirection: 'row',
              marginLeft: 11,
              marginRight: 11,
              marginTop: 20,
              maxWidth: [
                { minWidth: Breakpoints.Tablet, value: 600 },
                { minWidth: Breakpoints.Laptop, value: 700 },
                { minWidth: Breakpoints.BigScreen, value: 1000 },
              ],
              width: { minWidth: Breakpoints.Tablet, value: '100%' },
            },
            dimensions.width
          )}
        >
          {/* LeftView */}
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, paddingLeft: 9, paddingRight: 9, paddingTop: 9 },
              dimensions.width
            )}
          >
            {/* Name Of User */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['ResponsiveNameText'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['ResponsiveNameText'].style,
                  { fontSize: { minWidth: Breakpoints.Desktop, value: 28 } }
                ),
                dimensions.width
              )}
            >
              {Constants['USER']?._profile_of_user?.name}
            </Text>
            {/* Handle Of User */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['ResponsiveHandleText'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['ResponsiveHandleText'].style,
                dimensions.width
              )}
            >
              {Constants['USER']?._profile_of_user?.handle}
            </Text>
            {/* editBio */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.branding.tertiary,
                  borderRadius: 9,
                  borderWidth: 1,
                  flex: 1,
                  flexDirection: 'row',
                  marginBottom: 11,
                  marginRight: 11,
                  marginTop: 11,
                  minHeight: 60,
                  padding: 9,
                },
                dimensions.width
              )}
            >
              {/* Bio Of User */}
              <>
                {!Constants['USER']?._profile_of_user?.bio ? null : (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['ResponsiveBioText']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['ResponsiveBioText']
                          .style,
                        {
                          color: theme.colors.text.strong,
                          fontSize: {
                            minWidth: Breakpoints.Desktop,
                            value: 17,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {Constants['USER']?._profile_of_user?.bio}
                  </Text>
                )}
              </>
              {/* EditProfileToEnterBio */}
              <>
                {Constants['USER']?._profile_of_user?.bio ? null : (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['ResponsiveBioText']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['ResponsiveBioText']
                          .style,
                        {
                          color: palettes.App['Border Color'],
                          fontSize: {
                            minWidth: Breakpoints.Desktop,
                            value: 17,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Edit Profile to add a bio...'}
                  </Text>
                )}
              </>
            </View>
          </View>
          {/* RightView */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <>
              {!!isUploading ? null : (
                <View
                  {...GlobalStyles.ViewStyles(theme)['ResponsiveAvatarView']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['ResponsiveAvatarView']
                      .style,
                    dimensions.width
                  )}
                >
                  <>
                    {!Constants['USER']?._profile_of_user?.profile_photo
                      ?.url ? null : (
                      <ExpoImage
                        allowDownscaling={true}
                        cachePolicy={'disk'}
                        contentPosition={'center'}
                        transitionDuration={300}
                        transitionEffect={'cross-dissolve'}
                        transitionTiming={'ease-in-out'}
                        {...GlobalStyles.ExpoImageStyles(theme)[
                          'ResonsiveAvatarImage 2'
                        ].props}
                        resizeMode={'contain'}
                        source={imageSource(
                          `${Constants['USER']?._profile_of_user?.profile_photo?.url}`
                        )}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ExpoImageStyles(theme)[
                            'ResonsiveAvatarImage 2'
                          ].style,
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                </View>
              )}
            </>
            <Link
              accessible={true}
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'ProfileStackNavigator',
                    params: { screen: 'EditProfileScreen' },
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              selectable={false}
              {...GlobalStyles.LinkStyles(theme)['ResponsiveEditLink'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.LinkStyles(theme)['ResponsiveEditLink'].style,
                dimensions.width
              )}
              title={'Edit Profile'}
            />
          </View>
        </View>
        {/* TabView */}
        <TabView
          activeColor={theme.colors.branding.primary}
          iconPosition={'top'}
          keyboardDismissMode={'auto'}
          pressColor={theme.colors.branding.primary}
          scrollEnabled={false}
          swipeEnabled={true}
          tabBarPosition={'top'}
          {...GlobalStyles.TabViewStyles(theme)['ResponsiveTabView'].props}
          inactiveColor={theme.colors.text.medium}
          indicatorColor={theme.colors.branding.primary}
          initialTabIndex={
            props.route?.params?.initialTabIndex ?? defaultProps.initialTabIndex
          }
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TabViewStyles(theme)['ResponsiveTabView'].style,
              { textTransform: 'capitalize' }
            ),
            dimensions.width
          )}
          tabsBackgroundColor={theme.colors.background.base}
        >
          {/* MyLikesTab */}
          <TabViewItem
            {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].props}
            icon={'FontAwesome/heart'}
            style={StyleSheet.applyWidth(
              GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
              dimensions.width
            )}
            title={'Likes'}
          >
            {/* Custom Mansonry List */}
            <Utils.CustomCodeErrorBoundary>
              <TagLists.List
                endPoint="liked_tags"
                id={Constants['USER']?.id}
                theme={props.theme}
                navigation={props.navigation}
                refreshData={refreshData}
              />
            </Utils.CustomCodeErrorBoundary>
          </TabViewItem>
          {/* MyCommentsTab */}
          <TabViewItem
            {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].props}
            icon={'MaterialCommunityIcons/message-processing-outline'}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
                { paddingTop: 12 }
              ),
              dimensions.width
            )}
            title={'Comments'}
          >
            <NewXANOAPIApi.FetchGetCommentsGET
              handlers={{
                onData: fetchData => {
                  try {
                    setLikingCommentId('');
                  } catch (err) {
                    console.error(err);
                  }
                },
              }}
              page={0}
              perPage={15}
              user_id={Constants['USER']?.id}
            >
              {({ loading, error, data, refetchGetComments }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    <SimpleStyleFlatList
                      data={fetchData?.items}
                      decelerationRate={'normal'}
                      horizontal={false}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ??
                        listData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(listData)
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={
                        'Keyboard Aware Scroll View->TabView->MyCommentsTab->Fetch->List'
                      }
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      pagingEnabled={false}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <>
                            {/* Comments Container */}
                            <View
                              {...GlobalStyles.ViewStyles(theme)[
                                'Commets Container'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ViewStyles(theme)[
                                    'Commets Container'
                                  ].style,
                                  {
                                    backgroundColor:
                                      Constants['COLOR_SCHEME'] === 'light'
                                        ? theme.colors.background.base
                                        : theme.colors.branding.tertiary,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {/* CommentsSection */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flex: 1 },
                                  dimensions.width
                                )}
                              >
                                <Touchable
                                  onPress={() => {
                                    try {
                                      navigation.navigate(
                                        'BottomTabNavigator',
                                        {
                                          screen: 'HomeStackNavgiator',
                                          params: {
                                            screen: 'TagCommentPageScreen',
                                            params: {
                                              ClickedTagID: listData?.tags_id,
                                            },
                                          },
                                        }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  {/* CommentHeader */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flex: 1,
                                        flexDirection: 'row',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        justifyContent: 'flex-start',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* UserImage */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderRadius: 0,
                                          marginLeft: 5,
                                          marginRight: 7,
                                          marginTop: 5,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Touchable
                                        activeOpacity={0.8}
                                        disabledOpacity={0.8}
                                      >
                                        <>
                                          {!listData?._profile_of_user
                                            ?.profile_photo?.url ? null : (
                                            <ExpoImage
                                              allowDownscaling={true}
                                              cachePolicy={'disk'}
                                              contentPosition={'center'}
                                              resizeMode={'cover'}
                                              transitionDuration={300}
                                              transitionEffect={
                                                'cross-dissolve'
                                              }
                                              transitionTiming={'ease-in-out'}
                                              source={imageSource(
                                                `${listData?._profile_of_user?.profile_photo?.url}`
                                              )}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  borderRadius: 11,
                                                  height: 20,
                                                  width: 20,
                                                },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {listData?._profile_of_user
                                            ?.profile_photo?.url ? null : (
                                            <Icon
                                              color={
                                                theme.colors.background.brand
                                              }
                                              name={'FontAwesome/user-circle'}
                                              size={20}
                                            />
                                          )}
                                        </>
                                      </Touchable>
                                    </View>

                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flex: 1, marginBottom: 5 },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'baseline',
                                            alignSelf: 'auto',
                                            flexDirection: 'row',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Name */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'ResponsiveCommentName'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'ResponsiveCommentName'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?._profile_of_user?.handle}
                                        </Text>
                                        {/* Timeago */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'ResponsiveCommentTimeAgo'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'ResponsiveCommentTimeAgo'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'  '}
                                          {toRelativeTime(listData?.created_at)}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                  {/* CommentBody */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginLeft: 34,
                                        marginRight: 14,
                                        marginTop: 7,
                                        paddingBottom: 7,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'ResponsiveCommentBody'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'ResponsiveCommentBody'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.comment}
                                    </Text>
                                  </View>
                                </Touchable>
                              </View>
                              {/* CommentsActions */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginLeft: 34,
                                    paddingRight: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: 'flex-start' },
                                    dimensions.width
                                  )}
                                >
                                  {/* LikeButton */}
                                  <Touchable
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          setLikingCommentId(listData?.id);
                                          const likeCommentResult = (
                                            await newXANOAPIToggleCommentLikePOST.mutateAsync(
                                              { comment_id: listData?.id }
                                            )
                                          )?.json;
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 'center',
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 'center',
                                            },
                                          ],
                                          flexDirection: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 'column',
                                          },
                                          height: 36,
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(
                                          likingCommentId === listData?.id
                                        ) ? null : (
                                          <ActivityIndicator
                                            animating={true}
                                            hidesWhenStopped={true}
                                            size={'small'}
                                            {...GlobalStyles.ActivityIndicatorStyles(
                                              theme
                                            )['Activity Indicator'].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ActivityIndicatorStyles(
                                                theme
                                              )['Activity Indicator'].style,
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          likingCommentId !== listData?.id
                                        ) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Checkbox
                                              checkedIcon={'FontAwesome/heart'}
                                              color={theme.colors.text.strong}
                                              defaultValue={
                                                listData?._is_liked_by_user
                                              }
                                              disabled={true}
                                              size={20}
                                              uncheckedColor={
                                                theme.colors.text.strong
                                              }
                                              uncheckedIcon={'Feather/heart'}
                                            />
                                            <>
                                              {!(
                                                listData?._likes_count > 0
                                              ) ? null : (
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['label'].props}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['label'].style,
                                                      {
                                                        color: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value:
                                                              theme.colors.text
                                                                .strong,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value:
                                                              palettes.App
                                                                .White,
                                                          },
                                                        ],
                                                        fontSize: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 14,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value: 12,
                                                          },
                                                        ],
                                                        marginLeft: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 12,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value: 8,
                                                          },
                                                        ],
                                                        textAlign: {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value: 'center',
                                                        },
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listData?._likes_count}
                                                </Text>
                                              )}
                                            </>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                  </Touchable>
                                </View>
                                {/* View 2 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: 'flex-end' },
                                    dimensions.width
                                  )}
                                >
                                  {/* DeleteButton */}
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        setShowModal(true);
                                        setCommentIdToDelete(listData?.id);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          height: 36,
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Icon
                                        color={palettes.App['Border Color']}
                                        name={'Ionicons/trash-outline'}
                                        size={20}
                                      />
                                    </View>
                                  </Touchable>
                                </View>
                              </View>
                            </View>
                          </>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                      snapToAlignment={'start'}
                      {...GlobalStyles.SimpleStyleFlatListStyles(theme)[
                        'ResponsiveCommentsList 2'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.SimpleStyleFlatListStyles(theme)[
                          'ResponsiveCommentsList 2'
                        ].style,
                        dimensions.width
                      )}
                    />
                    {/* Empty View */}
                    <>
                      {!(fetchData?.items?.length === 0) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingTop: dimensions.height / 3 - 4,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Empty Text */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'ResponsiveHandleText'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'ResponsiveHandleText'
                                ].style,
                                {
                                  fontSize: 16,
                                  paddingBottom: 4,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'This user has no comments.'}
                          </Text>
                        </View>
                      )}
                    </>
                  </>
                );
              }}
            </NewXANOAPIApi.FetchGetCommentsGET>
          </TabViewItem>
          {/* MyTagsTab */}
          <TabViewItem
            {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].props}
            icon={'Ionicons/pricetag'}
            style={StyleSheet.applyWidth(
              GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
              dimensions.width
            )}
            title={'Tags'}
          >
            {/* Custom Mansonry List */}
            <Utils.CustomCodeErrorBoundary>
              <TagLists.List
                endPoint="user_tags"
                id={Constants['USER']?.id}
                theme={props.theme}
                navigation={props.navigation}
                refreshData={refreshData}
              />
            </Utils.CustomCodeErrorBoundary>
          </TabViewItem>
        </TabView>
      </SimpleStyleKeyboardAwareScrollView>

      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'fade'}
        presentationStyle={'fullScreen'}
        transparent={true}
        visible={showModal}
      >
        <BlurView
          experimentalBlurMethod={'none'}
          intensity={50}
          tint={'default'}
          {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
            dimensions.width
          )}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: '"rgba(252, 252, 252, 0.8)"',
                  },
                  {
                    minWidth: Breakpoints.Mobile,
                    value:
                      Platform.OS === 'android'
                        ? palettes.App.White
                        : palettes.App['Semi Transparent BG'],
                  },
                ],
                borderColor: palettes.App['Border Color'],
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 400,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* ModalHeader */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App['Border Color'],
                  flexDirection: 'row',
                  padding: 16,
                  paddingBottom: 8,
                },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      alignSelf: 'auto',
                      flex: 1,
                      fontFamily: 'Poppins_700Bold',
                      fontSize: 16,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Are you sure? '}
              </Text>
              {/* CloseModalButton */}
              <IconButton
                onPress={() => {
                  try {
                    setShowModal(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                color={theme.colors.text.strong}
                icon={'AntDesign/closecircleo'}
                style={StyleSheet.applyWidth(
                  { marginLeft: 16 },
                  dimensions.width
                )}
              />
            </View>
            {/* ModalBody */}
            <View
              style={StyleSheet.applyWidth(
                { flexGrow: 1, flexShrink: 0, padding: 16 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {
                  'You are about to delete this comment. You can not undo this action. Do you want to proceed? '
                }
              </Text>
            </View>
            {/* ModalFooter */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderRadius: 9,
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 16,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginRight: 4 },
                  dimensions.width
                )}
              >
                {/* CancelButton */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setShowModal(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: '"rgba(0, 0, 0, 0)"',
                        borderColor: theme.colors.branding.primary,
                        borderWidth: 1,
                        color: theme.colors.branding.primary,
                        lineHeight: 22,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginLeft: 4 },
                  dimensions.width
                )}
              >
                {/* ConfirmButton */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setIsDeleting(true);
                        const deleteResult = (
                          await newXANOAPIDeleteCommentDELETE.mutateAsync({
                            comments_id: commentIdToDelete,
                          })
                        )?.json;
                        console.log(deleteResult);
                        setIsDeleting(false);
                        setShowModal(false);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  disabled={isDeleting}
                  loading={isDeleting}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: palettes.App['Custom Color_11'],
                        lineHeight: 22,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Yes, Delete!'}
                />
              </View>
            </View>
          </View>
        </BlurView>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(ProfileScreen);