import React from 'react';
import {
  Button,
  Checkbox,
  ExpoImage,
  Icon,
  IconButton,
  Link,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  Surface,
  TabView,
  TabViewItem,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { BlurView } from 'expo-blur';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Modal, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as NewXANOAPIApi from '../apis/NewXANOAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import * as TagLists from '../custom-files/TagLists';
import formatGoToTaggerProfileScreen from '../global-functions/formatGoToTaggerProfileScreen';
import navigateToCompleteOnboarding from '../global-functions/navigateToCompleteOnboarding';
import pushToHistory from '../global-functions/pushToHistory';
import toRelativeTime from '../global-functions/toRelativeTime';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { initialTabIndex: 0, taggerId: 1 };

const TaggerProfileScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [currentAction, setCurrentAction] = React.useState('');
  const [isBlocking, setIsBlocking] = React.useState(false);
  const [likingCommentId, setLikingCommentId] = React.useState('');
  const [reloadList, setReloadList] = React.useState(0);
  const [screenTaggerId, setScreenTaggerId] = React.useState(
    props.route?.params?.taggerId ?? defaultProps.taggerId
  );
  const [showModal, setShowModal] = React.useState(false);
  const goBack = (Variables, setGlobalVariableValue) => {
    let history = Variables.HISTORY || [];

    //first remove the current screen from history
    history.pop();

    //then go back to the previous screen
    const previousScreen = history.pop();
    setGlobalVariableValue({
      key: 'HISTORY',
      value: history,
    });

    if (previousScreen) {
      props?.navigation?.navigate(
        previousScreen.screenName,
        previousScreen.params
      );
    }
  };

  const randomNumber = () => {
    return Math.floor(Math.random() * 900000) + 100000;
  };

  const showWarningMessage = () => {
    setShowModal(true);

    setTimeout(() => {
      setShowModal(false);
    }, 4000);
  };

  const subtract = (val1, val2) => {
    return (parseInt(val1) || 0) - (parseInt(val2) || 0);
  };
  const newXANOAPIToggleCommentLikePOST =
    NewXANOAPIApi.useToggleCommentLikePOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        pushToHistory(
          Variables,
          setGlobalVariableValue,
          segment,
          'TaggerProfileScreen',
          formatGoToTaggerProfileScreen(
            props.route?.params?.initialTabIndex ??
              defaultProps.initialTabIndex,
            props.route?.params?.taggerId ?? defaultProps.taggerId
          )
        );
        const meResult = (await NewXANOAPIApi.meGET(Constants))?.json;
        if (meResult?.code === 'ERROR_CODE_UNAUTHORIZED') {
          navigation.navigate('LoginScreen');
        }
        if (
          navigateToCompleteOnboarding(
            navigation,
            setGlobalVariableValue,
            Constants['USER']
          )
        ) {
          navigation.navigate('SignUpScreen', { completeOnBoarding: true });
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
    >
      {/* FloatingBackButtonShadow */}
      <Surface
        elevation={0}
        {...GlobalStyles.SurfaceStyles(theme)['FloatingBackButtonShadow'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.SurfaceStyles(theme)['FloatingBackButtonShadow'].style,
            { backgroundColor: theme.colors.background.base }
          ),
          dimensions.width
        )}
      >
        <Touchable
          onPress={() => {
            try {
              goBack(Variables, setGlobalVariableValue);
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors.text.strong}
              name={'AntDesign/leftcircle'}
              size={40}
            />
          </View>
        </Touchable>
      </Surface>

      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
            flex: 1,
            maxWidth: { minWidth: Breakpoints.Laptop, value: 1024 },
            minWidth: { minWidth: Breakpoints.Laptop, value: 550 },
            overflow: 'hidden',
            width: { minWidth: Breakpoints.Desktop, value: '100%' },
          },
          dimensions.width
        )}
      >
        <NewXANOAPIApi.FetchGetProfileDataGET
          handlers={{
            onData: fetchData => {
              try {
                console.log('reloading list');
              } catch (err) {
                console.error(err);
              }
            },
          }}
          reload={reloadList}
          user_id={props.route?.params?.taggerId ?? defaultProps.taggerId}
        >
          {({ loading, error, data, refetchGetProfileData }) => {
            const fetchData = data?.json;
            if (loading) {
              return (
                <>
                  {/* LoadingState */}
                  <View>
                    {/* Profile */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginLeft: 11,
                          marginRight: 11,
                          marginTop: 8,
                          maxHeight: 225,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flex: 1,
                            justifyContent: 'flex-start',
                            marginLeft: [
                              { minWidth: Breakpoints.Mobile, value: 70 },
                              { minWidth: Breakpoints.Desktop, value: 0 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        <View>
                          {/* bio */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'ResponsiveBioText'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'ResponsiveBioText'
                                ].style,
                                { marginTop: 10 }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Loading...'}
                          </Text>
                        </View>
                      </View>
                      {/* ProfileImageView */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)[
                          'ResponsiveAvatarView'
                        ].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)[
                              'ResponsiveAvatarView'
                            ].style,
                            { alignItems: 'center', justifyContent: 'center' }
                          ),
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          {...GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].props}
                          size={'large'}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.ActivityIndicatorStyles(theme)[
                              'Activity Indicator'
                            ].style,
                            dimensions.width
                          )}
                        />
                      </View>
                    </View>
                  </View>
                </>
              );
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <View>
                {/* Profile */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginLeft: 11,
                      marginRight: 11,
                      marginTop: 8,
                      maxHeight: 225,
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flex: 1,
                        justifyContent: 'flex-start',
                        marginLeft: [
                          { minWidth: Breakpoints.Mobile, value: 70 },
                          { minWidth: Breakpoints.Desktop, value: 0 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {/* Name */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['ResponsiveNameText']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['ResponsiveNameText']
                          .style,
                        dimensions.width
                      )}
                    >
                      {fetchData?.name}
                    </Text>
                    {/* Handle */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['ResponsiveHandleText']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['ResponsiveHandleText']
                            .style,
                          {
                            fontSize: {
                              minWidth: Breakpoints.Desktop,
                              value: 20,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {fetchData?.handle}
                    </Text>

                    <View>
                      {/* bio */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['ResponsiveBioText']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['ResponsiveBioText']
                              .style,
                            { marginTop: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {fetchData?.bio}
                      </Text>
                    </View>
                  </View>
                  {/* ProfileImageView */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['ResponsiveAvatarView']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['ResponsiveAvatarView']
                          .style,
                        { alignItems: 'center', justifyContent: 'center' }
                      ),
                      dimensions.width
                    )}
                  >
                    <>
                      {!fetchData?.profile_photo?.url ? null : (
                        <ExpoImage
                          allowDownscaling={true}
                          cachePolicy={'disk'}
                          contentPosition={'center'}
                          resizeMode={'cover'}
                          transitionDuration={300}
                          transitionEffect={'cross-dissolve'}
                          transitionTiming={'ease-in-out'}
                          {...GlobalStyles.ExpoImageStyles(theme)[
                            'ResonsiveAvatarImage 3'
                          ].props}
                          source={imageSource(
                            `${fetchData?.profile_photo?.url}`
                          )}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ExpoImageStyles(theme)[
                                'ResonsiveAvatarImage 3'
                              ].style,
                              { borderRadius: 50, height: 100, width: 100 }
                            ),
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    <>
                      {fetchData?.profile_photo?.url ? null : (
                        <Icon
                          color={theme.colors.text.medium}
                          name={'FontAwesome/user-circle'}
                          size={100}
                        />
                      )}
                    </>
                  </View>
                </View>
                {/* Actions */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'flex-end', padding: 12 },
                    dimensions.width
                  )}
                >
                  {/* RightView */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', width: 100 },
                      dimensions.width
                    )}
                  >
                    <>
                      {!isBlocking ? null : (
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          {...GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].props}
                          color={theme.colors.background.brand}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.ActivityIndicatorStyles(theme)[
                              'Activity Indicator'
                            ].style,
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Buttons */}
                    <>
                      {isBlocking ? null : (
                        <View>
                          {/* BlockButton */}
                          <>
                            {fetchData?._is_blocked_by_you ? null : (
                              <Link
                                accessible={true}
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      setIsBlocking(true);
                                      const toggleBlockResult = (
                                        await NewXANOAPIApi.toggleBlockGET(
                                          Constants,
                                          { user_id: fetchData?.user_id }
                                        )
                                      )?.json;
                                      setIsBlocking(false);
                                      setCurrentAction('blocked');
                                      showWarningMessage();
                                      setGlobalVariableValue({
                                        key: 'USER',
                                        value: toggleBlockResult,
                                      });
                                      await refetchGetProfileData();
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                selectable={false}
                                {...GlobalStyles.LinkStyles(theme)['Link']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)['Link']
                                      .style,
                                    { fontFamily: 'Poppins_400Regular' }
                                  ),
                                  dimensions.width
                                )}
                                title={'Block User'}
                              />
                            )}
                          </>
                          {/* UnBlockButton */}
                          <>
                            {!fetchData?._is_blocked_by_you ? null : (
                              <Link
                                accessible={true}
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      setIsBlocking(true);
                                      const toggleBlockResult = (
                                        await NewXANOAPIApi.toggleBlockGET(
                                          Constants,
                                          { user_id: fetchData?.user_id }
                                        )
                                      )?.json;
                                      setIsBlocking(false);
                                      setCurrentAction('unblocked');
                                      showWarningMessage();
                                      setGlobalVariableValue({
                                        key: 'USER',
                                        value: toggleBlockResult,
                                      });
                                      await refetchGetProfileData();
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                selectable={false}
                                {...GlobalStyles.LinkStyles(theme)['Link']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)['Link']
                                      .style,
                                    { fontFamily: 'Poppins_400Regular' }
                                  ),
                                  dimensions.width
                                )}
                                title={'Un Block User'}
                              />
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                </View>
              </View>
            );
          }}
        </NewXANOAPIApi.FetchGetProfileDataGET>
        <TabView
          activeColor={theme.colors.branding.primary}
          iconPosition={'top'}
          keyboardDismissMode={'auto'}
          pressColor={theme.colors.branding.primary}
          scrollEnabled={false}
          swipeEnabled={true}
          tabBarPosition={'top'}
          {...GlobalStyles.TabViewStyles(theme)['ResponsiveTabView'].props}
          inactiveColor={theme.colors.text.medium}
          indicatorColor={theme.colors.branding.primary}
          initialTabIndex={
            props.route?.params?.initialTabIndex ?? defaultProps.initialTabIndex
          }
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TabViewStyles(theme)['ResponsiveTabView'].style,
              { textTransform: 'capitalize' }
            ),
            dimensions.width
          )}
          tabsBackgroundColor={theme.colors.background.brand}
        >
          {/* TagsTab */}
          <TabViewItem
            {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].props}
            icon={'Ionicons/pricetag'}
            style={StyleSheet.applyWidth(
              GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
              dimensions.width
            )}
            title={'Tags'}
          >
            <Utils.CustomCodeErrorBoundary>
              <TagLists.List
                endPoint={'user_tags'}
                id={screenTaggerId}
                theme={props.theme}
                navigation={props.navigation}
              />
            </Utils.CustomCodeErrorBoundary>
          </TabViewItem>
          {/* CommentsTab */}
          <TabViewItem
            {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].props}
            icon={'MaterialCommunityIcons/message-processing-outline'}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
                { paddingTop: 12 }
              ),
              dimensions.width
            )}
            title={'Comments'}
          >
            <NewXANOAPIApi.FetchGetCommentsGET
              handlers={{
                onData: fetchData => {
                  try {
                    setLikingCommentId('');
                  } catch (err) {
                    console.error(err);
                  }
                },
              }}
              page={0}
              perPage={15}
              user_id={props.route?.params?.taggerId ?? defaultProps.taggerId}
            >
              {({ loading, error, data, refetchGetComments }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    <SimpleStyleFlatList
                      data={fetchData?.items}
                      decelerationRate={'normal'}
                      horizontal={false}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ??
                        listData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(listData)
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'View->Tab View->CommentsTab->Fetch->List'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      pagingEnabled={false}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <>
                            {/* Comments Container New */}
                            <View
                              {...GlobalStyles.ViewStyles(theme)[
                                'Commets Container'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ViewStyles(theme)[
                                    'Commets Container'
                                  ].style,
                                  {
                                    backgroundColor:
                                      Constants['COLOR_SCHEME'] === 'light'
                                        ? theme.colors.background.base
                                        : theme.colors.branding.tertiary,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {/* CommentsSection */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flex: 1 },
                                  dimensions.width
                                )}
                              >
                                <Touchable
                                  onPress={() => {
                                    try {
                                      navigation.navigate(
                                        'BottomTabNavigator',
                                        {
                                          screen: 'HomeStackNavgiator',
                                          params: {
                                            screen: 'ClickedTagPageScreen',
                                            params: {
                                              tagID: listData?.tags_id,
                                            },
                                          },
                                        }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  {/* CommentHeader */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flex: 1,
                                        flexDirection: 'row',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        justifyContent: 'flex-start',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* UserImage */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderRadius: 0,
                                          marginLeft: 5,
                                          marginRight: 7,
                                          marginTop: 5,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Touchable
                                        activeOpacity={0.8}
                                        disabledOpacity={0.8}
                                      >
                                        <>
                                          {!listData?._profile_of_user
                                            ?.profile_photo?.url ? null : (
                                            <ExpoImage
                                              allowDownscaling={true}
                                              cachePolicy={'disk'}
                                              contentPosition={'center'}
                                              resizeMode={'cover'}
                                              transitionDuration={300}
                                              transitionEffect={
                                                'cross-dissolve'
                                              }
                                              transitionTiming={'ease-in-out'}
                                              source={imageSource(
                                                `${listData?._profile_of_user?.profile_photo?.url}`
                                              )}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  borderRadius: 11,
                                                  height: 20,
                                                  width: 20,
                                                },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {listData?._profile_of_user
                                            ?.profile_photo?.url ? null : (
                                            <Icon
                                              color={
                                                theme.colors.background.brand
                                              }
                                              name={'FontAwesome/user-circle'}
                                              size={20}
                                            />
                                          )}
                                        </>
                                      </Touchable>
                                    </View>

                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flex: 1, marginBottom: 5 },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'baseline',
                                            alignSelf: 'auto',
                                            flexDirection: 'row',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Name */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'ResponsiveCommentName'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'ResponsiveCommentName'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?._profile_of_user?.handle}
                                        </Text>
                                        {/* Timeago */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'ResponsiveCommentTimeAgo'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'ResponsiveCommentTimeAgo'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'  '}
                                          {toRelativeTime(listData?.created_at)}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                  {/* CommentBody */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginLeft: 34,
                                        marginRight: 14,
                                        marginTop: 7,
                                        paddingBottom: 7,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'ResponsiveCommentBody'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'ResponsiveCommentBody'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.comment}
                                    </Text>
                                  </View>
                                </Touchable>
                              </View>
                              {/* CommentsActions */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderColor: theme.colors.text.medium,
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginLeft: 34,
                                    paddingRight: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: 'flex-start' },
                                    dimensions.width
                                  )}
                                >
                                  {/* LikeButton */}
                                  <Touchable
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          setLikingCommentId(listData?.id);
                                          const likeCommentResult = (
                                            await newXANOAPIToggleCommentLikePOST.mutateAsync(
                                              { comment_id: listData?.id }
                                            )
                                          )?.json;
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 'center',
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 'center',
                                            },
                                          ],
                                          flexDirection: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 'column',
                                          },
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(
                                          likingCommentId === listData?.id
                                        ) ? null : (
                                          <ActivityIndicator
                                            animating={true}
                                            hidesWhenStopped={true}
                                            size={'small'}
                                            {...GlobalStyles.ActivityIndicatorStyles(
                                              theme
                                            )['Activity Indicator'].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ActivityIndicatorStyles(
                                                theme
                                              )['Activity Indicator'].style,
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          likingCommentId !== listData?.id
                                        ) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                height: 36,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Checkbox
                                              checkedIcon={'FontAwesome/heart'}
                                              color={theme.colors.text.strong}
                                              defaultValue={
                                                listData?._is_liked_by_user
                                              }
                                              disabled={true}
                                              uncheckedColor={
                                                theme.colors.text.strong
                                              }
                                              uncheckedIcon={'Feather/heart'}
                                            />
                                            <>
                                              {!(
                                                listData?._likes_count > 0
                                              ) ? null : (
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['label'].props}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['label'].style,
                                                      {
                                                        color: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value:
                                                              theme.colors.text
                                                                .strong,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value:
                                                              palettes.App
                                                                .White,
                                                          },
                                                        ],
                                                        fontSize: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 14,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value: 12,
                                                          },
                                                        ],
                                                        marginLeft: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 12,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value: 8,
                                                          },
                                                        ],
                                                        textAlign: {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value: 'center',
                                                        },
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listData?._likes_count}
                                                </Text>
                                              )}
                                            </>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                  </Touchable>
                                </View>
                              </View>
                            </View>
                          </>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                      snapToAlignment={'start'}
                      {...GlobalStyles.SimpleStyleFlatListStyles(theme)[
                        'ResponsiveCommentsList 3'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.SimpleStyleFlatListStyles(theme)[
                          'ResponsiveCommentsList 3'
                        ].style,
                        dimensions.width
                      )}
                    />
                    {/* Empty View */}
                    <>
                      {!(fetchData?.items?.length === 0) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingTop: dimensions.height / 3 - 12,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Empty Text */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'ResponsiveHandleText'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'ResponsiveHandleText'
                                ].style,
                                {
                                  fontSize: 16,
                                  paddingBottom: 4,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'This user has no comments.'}
                          </Text>
                        </View>
                      )}
                    </>
                  </>
                );
              }}
            </NewXANOAPIApi.FetchGetCommentsGET>
          </TabViewItem>
          {/* LikesTab */}
          <TabViewItem
            {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].props}
            icon={'FontAwesome/heart'}
            style={StyleSheet.applyWidth(
              GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
              dimensions.width
            )}
            title={'Likes'}
          >
            <Utils.CustomCodeErrorBoundary>
              <TagLists.List
                endPoint={'liked_tags'}
                id={screenTaggerId}
                theme={props.theme}
                parentScreen="taggerProfile"
                navigation={props.navigation}
              />
            </Utils.CustomCodeErrorBoundary>
          </TabViewItem>
        </TabView>
      </View>
      {/* WarningModal */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'fade'}
        presentationStyle={'formSheet'}
        transparent={true}
        visible={showModal}
      >
        <BlurView
          experimentalBlurMethod={'none'}
          intensity={50}
          tint={'default'}
          {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
              { borderRadius: 16 }
            ),
            dimensions.width
          )}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: '"rgba(252, 252, 252, 0.8)"',
                borderColor: palettes.App['Border Color'],
                borderRadius: 16,
                borderWidth: 1,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* ModalHeader */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App['Border Color'],
                  flexDirection: 'row',
                  padding: 16,
                  paddingBottom: 4,
                },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      alignSelf: 'auto',
                      flex: 1,
                      fontFamily: 'Poppins_700Bold',
                      fontSize: 16,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Please Note...'}
              </Text>
              {/* CloseModalButton */}
              <IconButton
                onPress={() => {
                  try {
                    setShowModal(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                color={palettes.App.BackgroundPurple}
                icon={'AntDesign/closecircleo'}
                size={30}
                style={StyleSheet.applyWidth(
                  { marginLeft: 16 },
                  dimensions.width
                )}
              />
            </View>
            {/* ModalBody */}
            <View
              style={StyleSheet.applyWidth(
                { flexGrow: 1, flexShrink: 0, padding: 16 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {'You have '}
                {currentAction}
                {
                  ' this user. Please note that our system may take up to 15 minutes before this change takes effect.'
                }
              </Text>
            </View>
            {/* ModalFooter */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderRadius: 9,
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 16,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginRight: 4 },
                  dimensions.width
                )}
              >
                {/* CloseButton */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setShowModal(false);
                        (
                          await NewXANOAPIApi.toggleBlockGET(Constants, {
                            user_id:
                              props.route?.params?.taggerId ??
                              defaultProps.taggerId,
                          })
                        )?.json;
                        setReloadList(randomNumber());
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: '"rgba(0, 0, 0, 0)"',
                        borderColor: theme.colors.branding.primary,
                        borderWidth: 1,
                        color: theme.colors.branding.primary,
                        lineHeight: 22,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
              </View>
            </View>
          </View>
        </BlurView>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(TaggerProfileScreen);