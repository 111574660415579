import React from 'react';
import {
  ExpoImage,
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  Surface,
  Swiper,
  SwiperItem,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { BlurView } from 'expo-blur';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  ImageBackground,
  Platform,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as NewXANOAPIApi from '../apis/NewXANOAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import * as CustomComponents from '../custom-files/CustomComponents';
import getPercent from '../global-functions/getPercent';
import navigateToCompleteOnboarding from '../global-functions/navigateToCompleteOnboarding';
import pushToHistory from '../global-functions/pushToHistory';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const DiscoverScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const urlDecode = str => {
    // console.log('urlDecode',str, decodeURIComponent(str.replace(/\+/g,' ')));
    if (Platform.OS === 'web' && str) {
      return decodeURIComponent(str?.replace(/\+/g, ' '));
    }
    return str;
  };
  const [showDownloadModal, setShowDownloadModal] = React.useState(false);
  const [showFeatureMessage, setShowFeatureMessage] = React.useState(true);
  const [viewHeight, setViewHeight] = React.useState(0);
  const formatPromoClickData = item => {
    return {
      id: item.id,
      promo_name: item.promo_name,
    };
  };

  const formatSearchQueryData = query => {
    return {
      query: (query || '').toLowerCase(),
    };
  };

  const goBack = () => {
    let history = Variables.HISTORY || [];

    //first remove the current screen from history
    history.pop();

    //then go back to the previous screen
    const previousScreen = history.pop();
    setGlobalVariableValue({
      key: 'HISTORY',
      value: history,
    });

    if (previousScreen) {
      props?.navigation?.navigate(
        previousScreen.screenName,
        previousScreen.params
      );
    }
  };

  const showGoBackButton = () => {
    const history = Variables?.HISTORY;

    if (history.length > 1 && !Constants['showLoginModal']) {
      const lastScreen = history[history.length - 2];
      return lastScreen.screenName !== 'WelcomeScreen';
    } else return false;

    // console.log(history[history.length -1])
    // return (history.length > 1) //Boolean(props.route?.params?.goBackToTagId)  //
  };

  const showModalIn = (setGlobalVariableValue, duration) => {
    if (Platform.OS === 'web') {
      const timeout = setTimeout(() => {
        setGlobalVariableValue({
          key: 'showLoginModal',
          value: true,
        });
      }, duration || 30000);

      setGlobalVariableValue({
        key: 'loginModalNotShowYet',
        value: false,
      });
    }
  };

  const validEmail = () => {
    if (emailValue === '') return true;

    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue);
  };

  const validForm = () => {
    if (!emailValue) {
      setErrorMessage('Please enter your email');
      return false;
    }
    if (!passwordValue) {
      setErrorMessage('Please enter your password');
      return false;
    }

    if (validEmail(emailValue) === false) {
      setErrorMessage('Invalid e-mail address');
      return false;
    }

    setErrorMessage('');
    return true;
  };
  React.useEffect(() => {
    if (props?.route?.params) {
      const { pid, query, initialFacets } = props?.route?.params;
      if (pid || query || initialFacets) {
        navigation.navigate('BottomTabNavigator', {
          screen: 'HomeStackNavgiator',
          params: {
            screen: 'HomepageScreen',
            params: {
              query,
              pid,
              initialFacets,
            },
          },
        });
      }
    }
  }, []);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      /* hidden 'Set Variable' action */
      pushToHistory(
        Variables,
        setGlobalVariableValue,
        segment,
        'DiscoverScreen',
        undefined
      );
      if (!Constants['USER']?.id && Constants['loginModalNotShowYet']) {
        showModalIn(setGlobalVariableValue, 30000);
      }
      if (
        navigateToCompleteOnboarding(
          navigation,
          setGlobalVariableValue,
          Constants['USER']
        )
      ) {
        navigation.navigate('SignUpScreen', { completeOnBoarding: true });
      }
      if (Constants['USER']?.id) {
        setGlobalVariableValue({
          key: 'showLoginModal',
          value: false,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasBottomSafeArea={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
        },
        dimensions.width
      )}
    >
      <>
        {!showGoBackButton() ? null : (
          <Surface
            elevation={0}
            {...GlobalStyles.SurfaceStyles(theme)['FloatingBackButtonShadow']
              .props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.SurfaceStyles(theme)['FloatingBackButtonShadow']
                  .style,
                { backgroundColor: theme.colors.background.base }
              ),
              dimensions.width
            )}
          >
            {/* GoBackButton */}
            <>
              {!showGoBackButton() ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={theme.colors.text.strong}
                      name={'AntDesign/leftcircle'}
                      size={40}
                    />
                  </View>
                </Touchable>
              )}
            </>
          </Surface>
        )}
      </>
      {/* ResponsiveWrapper */}
      <View
        {...GlobalStyles.ViewStyles(theme)['ResponsiveWrapperView'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ViewStyles(theme)['ResponsiveWrapperView'].style,
            {
              alignItems: { minWidth: Breakpoints.BigScreen, value: 'center' },
              backgroundColor: null,
              width: dimensions.width,
            }
          ),
          dimensions.width
        )}
      >
        {/* Header */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'center',
              margin: 8,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <ExpoImage
              allowDownscaling={true}
              cachePolicy={'disk'}
              contentPosition={'center'}
              resizeMode={'cover'}
              transitionDuration={300}
              transitionEffect={'cross-dissolve'}
              transitionTiming={'ease-in-out'}
              {...GlobalStyles.ExpoImageStyles(theme)['Image 28'].props}
              source={imageSource(
                Constants['COLOR_SCHEME'] === 'light'
                  ? Images['LogoDarkFull']
                  : Images['LogoWhiteFull']
              )}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ExpoImageStyles(theme)['Image 28'].style,
                  { height: 50, width: 150 }
                ),
                dimensions.width
              )}
            />
          </View>
        </View>
        {/* Body */}
        <View
          style={StyleSheet.applyWidth(
            {
              flex: [
                { minWidth: Breakpoints.Mobile, value: 1 },
                { minWidth: Breakpoints.Desktop, value: 1 },
              ],
              overflow: 'hidden',
              paddingLeft: 12,
              paddingRight: 12,
            },
            dimensions.width
          )}
        >
          <NewXANOAPIApi.FetchGetDiscoverDataGET
            promoCount={
              dimensions.width >= Breakpoints.Laptop
                ? 200
                : dimensions.width >= Breakpoints.Tablet
                ? 6
                : 4
            }
          >
            {({ loading, error, data, refetchGetDiscoverData }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* WhatsHotView */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1.3 },
                      dimensions.width
                    )}
                  >
                    {/* SectionTitle */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: theme.colors.background.brand,
                            fontFamily: 'Poppins_600SemiBold',
                            fontSize: 16,
                            marginBottom: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {"What's Hot Now"}
                    </Text>
                    <Swiper
                      data={fetchData?.hot}
                      dotActiveColor={theme.colors.branding.primary}
                      dotColor={theme.colors.text.light}
                      dotsTouchable={true}
                      hideDots={false}
                      keyExtractor={(swiperData, index) =>
                        swiperData?.id ??
                        swiperData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(swiperData)
                      }
                      listKey={
                        'ResponsiveWrapper->Body->Fetch->WhatsHotView->Swiper'
                      }
                      minDistanceForAction={0.2}
                      minDistanceToCapture={5}
                      renderItem={({ item, index }) => {
                        const swiperData = item;
                        return (
                          <SwiperItem>
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                            >
                              <Pressable
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      if (swiperData?.url) {
                                        await WebBrowser.openBrowserAsync(
                                          `${swiperData?.url}`
                                        );
                                      } else {
                                        navigation.navigate(
                                          'BottomTabNavigator',
                                          {
                                            screen: 'HomeStackNavgiator',
                                            params: {
                                              screen: 'HomepageScreen',
                                              params: {
                                                query: swiperData?.query,
                                                initialFacets:
                                                  swiperData?.facet,
                                              },
                                            },
                                          }
                                        );
                                      }
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                style={StyleSheet.applyWidth(
                                  { height: '100%' },
                                  dimensions.width
                                )}
                              >
                                <ExpoImage
                                  allowDownscaling={true}
                                  cachePolicy={'disk'}
                                  contentPosition={'center'}
                                  resizeMode={'cover'}
                                  transitionDuration={300}
                                  transitionEffect={'cross-dissolve'}
                                  transitionTiming={'ease-in-out'}
                                  {...GlobalStyles.ExpoImageStyles(theme)[
                                    'Image 29'
                                  ].props}
                                  source={imageSource(
                                    `${swiperData?.image?.url}`
                                  )}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ExpoImageStyles(theme)[
                                        'Image 29'
                                      ].style,
                                      { height: '100%', width: '100%' }
                                    ),
                                    dimensions.width
                                  )}
                                />
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      height: '100%',
                                      justifyContent: 'flex-end',
                                      paddingBottom: 40,
                                      paddingLeft: 15,
                                      paddingRight: 15,
                                      position: 'absolute',
                                      width: '100%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Title */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={[
                                      StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            color: palettes.Brand.Background,
                                            fontFamily: 'Poppins_600SemiBold',
                                            fontSize: 16,
                                            textAlign: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      ),
                                      {
                                        textShadowColor: 'black',
                                        textShadowOffset: {
                                          width: 1,
                                          height: 1,
                                        },
                                        textShadowRadius: 3,
                                      },
                                    ]}
                                    contentContainerStyle={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {swiperData?.title}
                                  </Text>
                                </View>
                              </Pressable>
                            </View>
                          </SwiperItem>
                        );
                      }}
                      vertical={false}
                      {...GlobalStyles.SwiperStyles(theme)['Swiper'].props}
                      loop={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SwiperStyles(theme)['Swiper'].style,
                          {
                            borderRadius: 5,
                            height: '100%',
                            overflow: 'hidden',
                          }
                        ),
                        dimensions.width
                      )}
                      timeout={5}
                    />
                  </View>
                  {/* ExploreButtons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Tablet,
                          value: 'rgba(0, 0, 0, 0)',
                        },
                        marginTop: 32,
                        minHeight: [
                          { minWidth: Breakpoints.Mobile, value: 225 },
                          { minWidth: Breakpoints.Tablet, value: 310 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {/* SectionTitle */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: theme.colors.background.brand,
                            fontFamily: 'Poppins_600SemiBold',
                            fontSize: 16,
                            marginBottom: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Explore The App'}
                    </Text>
                    <SimpleStyleFlatList
                      data={fetchData?.promo_buttons}
                      decelerationRate={'normal'}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ??
                        listData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(listData)
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={
                        'ResponsiveWrapper->Body->Fetch->ExploreButtons->List'
                      }
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      pagingEnabled={false}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderRadius: 5,
                                height: 90,
                                minHeight: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 130,
                                },
                                overflow: 'hidden',
                                width: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value:
                                      getPercent(dimensions.width, 50) - 16,
                                  },
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value:
                                      dimensions.width > 1024
                                        ? getPercent(1024, 33) - 16
                                        : getPercent(dimensions.width, 33) - 16,
                                  },
                                  { minWidth: Breakpoints.Desktop, value: 327 },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            <Pressable
                              onPress={() => {
                                try {
                                  navigation.navigate('BottomTabNavigator', {
                                    screen: 'HomeStackNavgiator',
                                    params: {
                                      screen: 'HomepageScreen',
                                      params: {
                                        pid: listData?.id,
                                        query: '',
                                        initialFacets: [],
                                      },
                                    },
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <Utils.CustomCodeErrorBoundary>
                                <CustomComponents.FadeCarousel
                                  images={listData?.images}
                                  containerStyle={{
                                    flex: 1,
                                  }}
                                  fadeDuration={2000}
                                  stillDuration={5000}
                                  start={true}
                                  height={
                                    dimensions.width >= Breakpoints.Laptop
                                      ? 200
                                      : dimensions.width >= Breakpoints.Tablet
                                      ? 130
                                      : 90
                                  }
                                  delay={
                                    (Math.floor(Math.random() * 5) + 1) * 1000
                                  }
                                />
                              </Utils.CustomCodeErrorBoundary>
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    height: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 90,
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 130,
                                      },
                                    ],
                                    justifyContent: 'center',
                                    width: '100%',
                                    zIndex: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={[
                                    StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: palettes.Brand.Background,
                                          fontFamily: 'Poppins_600SemiBold',
                                          fontSize: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 16,
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: 18,
                                            },
                                          ],
                                          margin: 16,
                                          textAlign: 'center',
                                        }
                                      ),
                                      dimensions.width
                                    ),
                                    {
                                      textShadowColor: 'black',
                                      textShadowOffset: { width: 1, height: 1 },
                                      textShadowRadius: 3,
                                    },
                                  ]}
                                  contentContainerStyle={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {listData?.promo_name}
                                </Text>
                              </View>
                            </Pressable>
                          </View>
                        );
                      }}
                      snapToAlignment={'start'}
                      horizontal={false}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: {
                            minWidth: Breakpoints.Desktop,
                            value: 'flex-start',
                          },
                          flex: 1,
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          gap: 8,
                          justifyContent: 'flex-start',
                          maxHeight: {
                            minWidth: Breakpoints.Laptop,
                            value: getPercent(dimensions.height, 33),
                          },
                        },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* TrendingThisWeek */}
                  <View
                    onLayout={event => {
                      try {
                        setViewHeight(event?.nativeEvent?.layout?.height);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        flex: 0.7,
                        marginTop: { minWidth: Breakpoints.Tablet, value: 12 },
                      },
                      dimensions.width
                    )}
                  >
                    {/* SectionTitle */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: theme.colors.background.brand,
                            fontFamily: 'Poppins_600SemiBold',
                            fontSize: 16,
                            marginBottom: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Trending This Month'}
                    </Text>
                    <SimpleStyleFlatList
                      data={fetchData?.trending}
                      decelerationRate={'normal'}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ??
                        listData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(listData)
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={
                        'ResponsiveWrapper->Body->Fetch->TrendingThisWeek->List'
                      }
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      pagingEnabled={false}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <Pressable
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  if (listData?.url) {
                                    await WebBrowser.openBrowserAsync(
                                      `${listData?.url}`
                                    );
                                  }
                                  if (listData?.promotions_id) {
                                    navigation.navigate('BottomTabNavigator', {
                                      screen: 'HomeStackNavgiator',
                                      params: {
                                        screen: 'HomepageScreen',
                                        params: {
                                          pid: listData?.promotions_id,
                                          query: listData?.query
                                            ? listData?.query
                                            : null,
                                        },
                                      },
                                    });
                                  }
                                  if (listData?.facet) {
                                    navigation.navigate('BottomTabNavigator', {
                                      screen: 'HomeStackNavgiator',
                                      params: {
                                        screen: 'HomepageScreen',
                                        params: {
                                          query: listData?.query
                                            ? listData?.query
                                            : null,
                                          initialFacets: listData?.facet,
                                        },
                                      },
                                    });
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                          >
                            <ImageBackground
                              {...GlobalStyles.ImageBackgroundStyles(theme)[
                                'Image Background'
                              ].props}
                              resizeMode={'cover'}
                              source={imageSource(`${listData?.image?.url}`)}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageBackgroundStyles(theme)[
                                    'Image Background'
                                  ].style,
                                  {
                                    alignItems: 'center',
                                    borderRadius: 5,
                                    height: '100%',
                                    justifyContent: 'flex-end',
                                    overflow: 'hidden',
                                    width: 135,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={[
                                  StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.Brand.Background,
                                        fontFamily: 'Poppins_600SemiBold',
                                        fontSize: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 16,
                                        },
                                        margin: 12,
                                        textAlign: 'center',
                                      }
                                    ),
                                    dimensions.width
                                  ),
                                  {
                                    textShadowColor: 'black',
                                    textShadowOffset: { width: 1, height: 1 },
                                    textShadowRadius: 3,
                                  },
                                ]}
                                contentContainerStyle={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {listData?.title}
                              </Text>
                            </ImageBackground>
                          </Pressable>
                        );
                      }}
                      snapToAlignment={'start'}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      style={StyleSheet.applyWidth(
                        { gap: 8, height: '100%' },
                        dimensions.width
                      )}
                    />
                  </View>
                </>
              );
            }}
          </NewXANOAPIApi.FetchGetDiscoverDataGET>
        </View>
      </View>
      {/* DownloadModal */}
      <>
        {!showDownloadModal ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['LoginModalView'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['LoginModalView'].style,
                { height: dimensions.height, width: dimensions.width }
              ),
              dimensions.width
            )}
          >
            <BlurView
              experimentalBlurMethod={'none'}
              intensity={50}
              tint={'default'}
              {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
                  {
                    flex: 1,
                    height: dimensions.height,
                    width: dimensions.width,
                  }
                ),
                dimensions.width
              )}
            >
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors.background.brand,
                    borderColor: palettes.App['Border Color'],
                    borderRadius: 16,
                    borderWidth: 1,
                    maxWidth: 400,
                    opacity: 1,
                    paddingBottom: 40,
                    paddingTop: 40,
                    width: '90%',
                  },
                  dimensions.width
                )}
              >
                {/* CloseButton */}
                <IconButton
                  onPress={() => {
                    try {
                      setShowDownloadModal(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  icon={'AntDesign/closecircleo'}
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 8, top: 8 },
                    dimensions.width
                  )}
                />
                {/* ModalHeader */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      justifyContent: 'center',
                      padding: 16,
                      paddingBottom: 4,
                    },
                    dimensions.width
                  )}
                >
                  <ExpoImage
                    allowDownscaling={true}
                    cachePolicy={'disk'}
                    contentPosition={'center'}
                    resizeMode={'cover'}
                    transitionDuration={300}
                    transitionEffect={'cross-dissolve'}
                    transitionTiming={'ease-in-out'}
                    {...GlobalStyles.ExpoImageStyles(theme)['Image 30'].props}
                    source={imageSource(Images['LogoPurpleFull'])}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ExpoImageStyles(theme)['Image 30'].style,
                        { height: 75, width: 225 }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
                {/* ModalBody */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexGrow: 1, flexShrink: 0, padding: 16 },
                    dimensions.width
                  )}
                >
                  {/* DownloadText */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['ResponsiveNameText']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['ResponsiveNameText']
                          .style,
                        {
                          color: theme.colors.text.strong,
                          marginBottom: 40,
                          marginTop: 40,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Download the Montage app'}
                    {/* DownloadText */}
                    <>
                      {!showFeatureMessage ? null : (
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)[
                            'ResponsiveNameText'
                          ].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)[
                                'ResponsiveNameText'
                              ].style,
                              {
                                color: theme.colors.text.strong,
                                marginBottom: 40,
                                marginTop: 40,
                                textAlign: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {' to activate this feature'}
                        </Text>
                      )}
                    </>
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {/* AppStoreButton */}
                    <Pressable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://apps.apple.com/us/app/montage-search-tv-style/id6445878135'
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderRadius: 5,
                            flex: 1,
                            margin: 5,
                            overflow: 'hidden',
                          },
                          dimensions.width
                        )}
                      >
                        <ExpoImage
                          allowDownscaling={true}
                          cachePolicy={'disk'}
                          contentPosition={'center'}
                          resizeMode={'cover'}
                          transitionDuration={300}
                          transitionEffect={'cross-dissolve'}
                          transitionTiming={'ease-in-out'}
                          {...GlobalStyles.ExpoImageStyles(theme)['Image 31']
                            .props}
                          source={imageSource(Images['AppleAppStoreButton'])}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ExpoImageStyles(theme)['Image 31']
                                .style,
                              { height: 40, width: 130 }
                            ),
                            dimensions.width
                          )}
                        />
                      </View>
                    </Pressable>
                    {/* PlayStoreButton */}
                    <Pressable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://play.google.com/store/apps/details?id=com.searchmontage.app&hl=en-US&ah=nQPwrvIrI6Uex14KJwiIkePiWGQ'
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderRadius: 5,
                            flex: 1,
                            margin: 5,
                            overflow: 'hidden',
                          },
                          dimensions.width
                        )}
                      >
                        <ExpoImage
                          allowDownscaling={true}
                          cachePolicy={'disk'}
                          contentPosition={'center'}
                          resizeMode={'cover'}
                          transitionDuration={300}
                          transitionEffect={'cross-dissolve'}
                          transitionTiming={'ease-in-out'}
                          {...GlobalStyles.ExpoImageStyles(theme)['Image 32']
                            .props}
                          source={imageSource(Images['PlayStoreButton'])}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ExpoImageStyles(theme)['Image 32']
                                .style,
                              { height: 40, width: 130 }
                            ),
                            dimensions.width
                          )}
                        />
                      </View>
                    </Pressable>
                  </View>
                </View>
              </View>
            </BlurView>
          </View>
        )}
      </>
      {/* FloatingGetAppButtonView */}
      <>
        {!(Platform.OS === 'web') ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                borderRadius: 16,
                bottom: 70,
                left: 20,
                position: 'absolute',
                width: 180,
                zIndex: 99,
              },
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  setShowDownloadModal(true);
                  setShowFeatureMessage(false);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Surface
                {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                elevation={3}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors.branding.primary,
                      borderRadius: 16,
                      flexDirection: 'row',
                      justifyContent: 'center',
                      padding: 12,
                    }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={palettes.Brand.Background}
                  name={'Feather/download'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.Brand.Background,
                        fontFamily: 'Poppins_700Bold',
                        fontSize: 16,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Get the app'}
                </Text>
              </Surface>
            </Pressable>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(DiscoverScreen);