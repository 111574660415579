const pushToHistory = (
  Variables,
  setGlobalVariableValue,
  segment,
  screenName,
  params
) => {
  try {
    if (screenName) {
      const currentHistory = Variables.HISTORY || [];

      const lastHistory = currentHistory[currentHistory.length - 1];
      const newParams = {};

      Object.keys(params || {}).forEach(key => {
        if (
          params[key] === 'NaN' ||
          params[key] === 'null' ||
          params[key] === 'undefined' ||
          params[key] === null ||
          params[key] === undefined ||
          params[key] === NaN
        )
          newParams[key] = '';
        else newParams[key] = params[key];
      });

      //check if last history is same as current screen and params
      if (
        lastHistory &&
        lastHistory.screenName === screenName &&
        JSON.stringify(lastHistory.params) === JSON.stringify(newParams)
      ) {
        return;
      }
      currentHistory.push({
        screenName,
        params: newParams,
      });

      setGlobalVariableValue({
        key: 'HISTORY',
        value: currentHistory,
      });
      console.log(currentHistory);
      segment.screen(screenName);
    }
  } catch (err) {
    console.error(err);
  }
};

export default pushToHistory;
