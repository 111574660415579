import React from 'react';
import {
  Button,
  ExpoImage,
  Icon,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Notifications from 'expo-notifications';
import { Alert, Platform, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as AuthComponents from '../custom-files/AuthComponents';
import * as CustomCode from '../custom-files/CustomCode';
import * as Lib from '../custom-files/Lib';
import navigateToCompleteOnboarding from '../global-functions/navigateToCompleteOnboarding';
import pushToHistory from '../global-functions/pushToHistory';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const WelcomeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const checkUpdateVersion = platformVersions => {
    // const Linking = Lib.Linking;
    // const Application = Lib.Application;
    // const semver = Lib.semver;
    // const currentAppVersion = Application.nativeApplicationVersion;
    // const currentPlatformLog = platformVersions.find(
    //   (version) => version.platform === Platform.OS
    // );
    // if (currentPlatformLog) {
    //   const storeUrl = currentPlatformLog?.store_url;
    //   const needCheckVersion = currentPlatformLog?.version;
    //   const releasedType = semver.diff(currentAppVersion, needCheckVersion);
    //   const needUpdate = semver.lt(currentAppVersion, needCheckVersion);
    //   if (needUpdate === false) {
    //     return;
    //   }
    //   if (releasedType === 'major') {
    //     return Alert.alert(
    //       'Update Available',
    //       'We released a new version of the app. Please update for an improved experience.',
    //       [
    //         {
    //           text: 'Update Now',
    //           onPress: () => Linking.openURL(storeUrl),
    //         },
    //       ],
    //       { cancelable: false }
    //     );
    //   } else {
    //     Alert.alert(
    //       'Update Available',
    //       'We released a new version of the app. Please update for an improved experience.',
    //       [
    //         {
    //           text: 'Update Now',
    //           onPress: () => Linking.openURL(storeUrl),
    //         },
    //         {
    //           text: 'Later',
    //           onPress: () => console.log('Later Pressed'),
    //           style: 'cancel',
    //         },
    //       ]
    //     );
    //   }
    // } else {
    //   console.warn('Platform version not found');
    // }
  };
  const lastNotificationResponse = Notifications.useLastNotificationResponse();

  React.useEffect(() => {
    // console.log('lastNotificationResponse', lastNotificationResponse);
    if (
      lastNotificationResponse &&
      lastNotificationResponse.actionIdentifier ===
        Notifications.DEFAULT_ACTION_IDENTIFIER
    ) {
      const screen =
        lastNotificationResponse?.notification?.request?.content?.data?.screen;
      const id =
        lastNotificationResponse?.notification?.request?.content?.data?.id;
      const query =
        lastNotificationResponse?.notification?.request?.content?.data?.query;
      const index =
        lastNotificationResponse?.notification?.request?.content?.data?.index;

      segment.track('Notification Clicked', {
        platform: Platform.OS,
        data: lastNotificationResponse?.notification?.request?.content?.data,
      });

      try {
        let screenParams = {};
        if (screen === 'HomepageScreen') {
          screenParams = { query, pid: id };
        } else if (screen === 'ProfileScreen') {
          screenParams = { initialTabIndex: index };
        } else if (screen === 'TaggerProfileScreen') {
          screenParams = {
            taggerId: id,
            initialTabIndex: index,
          };
        } else if (screen === 'ClickedTagPageScreen') {
          screenParams = {
            tagID: id,
          };
        }

        if (
          [
            'HomepageScreen',
            'ProfileScreen',
            'TaggerProfileScreen',
            'ClickedTagPageScreen',
          ].includes(screen)
        ) {
          props.navigation.navigate(screen, screenParams);
        }
      } catch (err) {
        console.error(err);
      }
      return;
    }
  }, [lastNotificationResponse]);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        pushToHistory(
          Variables,
          setGlobalVariableValue,
          segment,
          'WelcomeScreen',
          undefined
        );
        if (
          navigateToCompleteOnboarding(
            navigation,
            setGlobalVariableValue,
            Constants['USER']
          )
        ) {
          navigation.navigate('SignUpScreen', { completeOnBoarding: true });
        }
        if (Constants['USER']?.id) {
          navigation.navigate('BottomTabNavigator', {
            screen: 'DiscoverScreen',
          });
        }
        await Notifications.getBadgeCountAsync();
        /* hidden 'Run a Custom Function' action */
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
          backgroundColor: theme.colors.background.brand,
          justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
        },
        dimensions.width
      )}
    >
      {/* Wrapper */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Wrapper'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Wrapper'].style, {
            backgroundColor: theme.colors.background.brand,
            flex: 1,
            paddingBottom: 48,
            paddingTop: 48,
          }),
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flex: [
                { minWidth: Breakpoints.Mobile, value: 1 },
                { minWidth: Breakpoints.Desktop, value: 1 },
              ],
              justifyContent: 'center',
              marginBottom: 30,
            },
            dimensions.width
          )}
        >
          {/* Image 2 */}
          <ExpoImage
            allowDownscaling={true}
            cachePolicy={'disk'}
            contentPosition={'center'}
            resizeMode={'cover'}
            transitionDuration={300}
            transitionEffect={'cross-dissolve'}
            transitionTiming={'ease-in-out'}
            {...GlobalStyles.ExpoImageStyles(theme)['Image 4'].props}
            source={imageSource(
              Constants['COLOR_SCHEME'] === 'light'
                ? Images['LogoDarkFull']
                : Images['LogoWhiteFull']
            )}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ExpoImageStyles(theme)['Image 4'].style,
                {
                  height: { minWidth: Breakpoints.Desktop, value: 200 },
                  width: [
                    { minWidth: Breakpoints.Mobile, value: 300 },
                    { minWidth: Breakpoints.Desktop, value: 600 },
                  ],
                }
              ),
              dimensions.width
            )}
          />
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'space-between',
              height: { minWidth: Breakpoints.BigScreen, value: 300 },
              justifyContent: 'space-evenly',
              minHeight: 255,
              paddingLeft: 16,
              paddingRight: 16,
              width: { minWidth: Breakpoints.Tablet, value: '50%' },
            },
            dimensions.width
          )}
        >
          {/* SignupButton */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('SignUpScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.branding.primary,
                borderColor: palettes.Brand['Strong Inverse'],
                borderRadius: 8,
                color: palettes.Brand['Strong Inverse'],
                fontFamily: 'Poppins_700Bold',
                fontSize: [
                  { minWidth: Breakpoints.Mobile, value: 18 },
                  { minWidth: Breakpoints.Desktop, value: 22 },
                  { minWidth: Breakpoints.BigScreen, value: 24 },
                ],
                height: [
                  { minWidth: Breakpoints.Mobile, value: 45 },
                  { minWidth: Breakpoints.BigScreen, value: 65 },
                ],
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={'Sign Up'}
          />
          {/* LoginButton */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('LoginScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: '"rgba(0, 0, 0, 0)"',
                borderColor: theme.colors.text.strong,
                borderRadius: 8,
                borderWidth: 1,
                color: theme.colors.text.strong,
                fontFamily: 'Poppins_700Bold',
                fontSize: [
                  { minWidth: Breakpoints.Mobile, value: 18 },
                  { minWidth: Breakpoints.Desktop, value: 22 },
                  { minWidth: Breakpoints.BigScreen, value: 24 },
                ],
                height: [
                  { minWidth: Breakpoints.Mobile, value: 45 },
                  { minWidth: Breakpoints.BigScreen, value: 65 },
                ],
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={'Login'}
          />
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: theme.colors.text.strong,
                fontSize: { minWidth: Breakpoints.Desktop, value: 14 },
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {'- OR -'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row' },
              dimensions.width
            )}
          >
            {/* GoogleView */}
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              {/* GoogleAuthWrapper */}
              <Utils.CustomCodeErrorBoundary>
                <AuthComponents.GoogleLogin
                  navigation={props.navigation}
                  theme={props.theme}
                  buttonType="signup"
                  setErrorMessage={setErrorMessage}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'auto',
                        backgroundColor: palettes.Brand.Background,
                        borderColor: palettes.App['Border Color'],
                        borderRadius: 12,
                        borderWidth: 1,
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        maxHeight: [
                          { minWidth: Breakpoints.Mobile, value: 50 },
                          { minWidth: Breakpoints.BigScreen, value: 65 },
                        ],
                        paddingBottom: 8,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    <ExpoImage
                      allowDownscaling={true}
                      cachePolicy={'disk'}
                      contentPosition={'center'}
                      resizeMode={'cover'}
                      transitionDuration={300}
                      transitionEffect={'cross-dissolve'}
                      transitionTiming={'ease-in-out'}
                      {...GlobalStyles.ExpoImageStyles(theme)['Image 3'].props}
                      source={imageSource(Images['Google'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ExpoImageStyles(theme)['Image 3'].style,
                          {
                            height: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Desktop, value: 24 },
                              { minWidth: Breakpoints.BigScreen, value: 32 },
                            ],
                            width: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Desktop, value: 24 },
                              { minWidth: Breakpoints.BigScreen, value: 32 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                </AuthComponents.GoogleLogin>
              </Utils.CustomCodeErrorBoundary>
            </View>
            {/* AppleView */}
            <>
              {!(Platform.OS === 'ios') ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, marginLeft: 12 },
                    dimensions.width
                  )}
                >
                  {/* AppleWrapper */}
                  <Utils.CustomCodeErrorBoundary>
                    <AuthComponents.AppleWrapper
                      navigation={props.navigation}
                      theme={props.theme}
                      setIsLoading={setIsLoading}
                      isLoading={isLoading}
                      setErrorMessage={setErrorMessage}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            alignSelf: 'auto',
                            backgroundColor: palettes.Brand.Background,
                            borderColor: palettes.App['Border Color'],
                            borderRadius: 12,
                            borderWidth: 1,
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            maxHeight: [
                              { minWidth: Breakpoints.Mobile, value: 50 },
                              { minWidth: Breakpoints.BigScreen, value: 65 },
                            ],
                            paddingBottom: 8,
                            paddingTop: 8,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon name={'AntDesign/apple1'} size={32} />
                      </View>
                    </AuthComponents.AppleWrapper>
                  </Utils.CustomCodeErrorBoundary>
                </View>
              )}
            </>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(WelcomeScreen);