import palettes from '../themes/palettes';
import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  ActivityIndicator,
  Platform,
  Pressable,
} from 'react-native';
import { ExpoImage } from './CustomCode';
import { Checkbox, Swiper, SwiperItem, VideoPlayer } from '@draftbit/ui';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as GlobalStyles from '../GlobalStyles.js';
import openShareUtil from '../utils/openShare';
import { useAnalytics } from '@segment/analytics-react-native';
import * as WebBrowser from 'expo-web-browser';

export const Card = ({
  item,
  theme,
  navigation,
  showDownloadModal,
  isVisible = false,
}) => {
  if (!item?.id) return null;

  const variables = GlobalVariables.useValues();
  const setVariables = GlobalVariables.useSetValue();
  const { likesCache, sharesCache } = variables;
  const isLikedByUserCache = variables.isLikedByUserCache;
  const [cardWidth, setCardWidth] = useState(0);

  const [isLiking, setIsLiking] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  const segment = useAnalytics();

  React.useEffect(() => {
    if (item?.type === 'ad' && isVisible) {
      // console.log('ad displayed ',item.id)
      segment.track('Ad Displayed', {
        platform: Platform.OS,
        id: item.id,
      });
    }
  }, [isVisible]);

  if (item?.type === 'ad') {
    return (
      <>
        <View
          style={{
            ...styles(theme).container,
            ...styles(theme).adContainer,
            // borderColor: isVisible? 'red':palettes.App['Border Color'],
          }}
          onLayout={event => {
            var { width } = event.nativeEvent.layout;
            setCardWidth(width - 2); // remove border widths
          }}
        >
          <View
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              backgroundColor: 'rgba(37, 37, 49, 0.8)',
              zIndex: 100,
              paddingHorizontal: 12,
              paddingVertical: 3,
              borderBottomEndRadius: 0,
              borderBottomStartRadius: 4,
            }}
          >
            <Text style={{ color: 'white' }}>Promoted</Text>
          </View>
          {item.ads.length > 1 ? (
            <Swiper
              dotActiveColor={theme.colors.branding.primary}
              dotColor={theme.colors.text.light}
              dotsTouchable={true}
              timeout={5}
              loop={true}
              style={{
                width: '100%',
                minHeight:
                  (cardWidth * item?.ads[0]?.ad_attachment?.meta?.height) /
                    item?.ads[0]?.ad_attachment?.meta?.width || 175,
              }}
            >
              {item.ads.map((ad, index) => (
                <SwiperItem key={index}>
                  <AdView item={ad} theme={theme} cardWidth={cardWidth} />
                </SwiperItem>
              ))}
            </Swiper>
          ) : (
            <AdView item={item.ads[0]} theme={theme} cardWidth={cardWidth} />
          )}
        </View>
      </>
    );
  }

  return (
    <>
      <View
        style={{
          ...styles(theme).container,
          ...(!item?.validated ? styles(theme).notValidCard : undefined),
        }}
        onLayout={event => {
          var { width } = event.nativeEvent.layout;
          setCardWidth(width - 2); // remove borderwidths
        }}
      >
        {!item?.validated ? (
          <View style={styles(theme).errorContainer}>
            <Text style={styles(theme).errorText}>Waiting For Approval</Text>
          </View>
        ) : null}

        <View
          style={{
            ...styles(theme).cardHeader,
            minHeight:
              (cardWidth *
                item?.['_tag_photos']?.[0]?.tag_photo?.meta?.height) /
                item?.['_tag_photos']?.[0]?.tag_photo?.meta?.width || 175,
          }}
        >
          <ExpoImage
            theme={theme}
            style={{
              borderTopLeftRadius: item?.validated ? 4 : 0,
              borderTopRightRadius: item?.validated ? 4 : 0,
            }}
            cardWidth={cardWidth}
            cardHeight={
              (cardWidth *
                item?.['_tag_photos']?.[0]?.tag_photo?.meta?.height) /
              item?.['_tag_photos']?.[0]?.tag_photo?.meta?.width
            }
            url={item?.['_tag_photos']?.[0]?.tag_photo?.url}
          />
        </View>
        <View style={styles(theme).labelContainer}>
          <Text style={styles(theme).titleLabel}>
            {item?._tag_content_details?.series_name}{' '}
            {item?._tag_content_details?.episode}
          </Text>
          <Text style={styles(theme).descriptionLabel}>{item?.tag_name}</Text>
        </View>

        {item?.validated && (
          <View style={styles(theme).actionMenuContainer}>
            <View style={styles(theme).favoriteView}>
              {isLiking ? (
                <ActivityIndicator size="small" color={palettes.App['White']} />
              ) : (
                <Checkbox
                  onCheck={() => {
                    const isLoggedIn =
                      variables.AUTH_TOKEN !== null &&
                      variables.AUTH_TOKEN !== '';

                    if (isLoggedIn) {
                      likesCache[item.id] = likesCache[item.id] + 1;
                      isLikedByUserCache[item.id] = true;
                      setVariables({ key: 'likesCache', value: likesCache });
                      setVariables({
                        key: 'isLikedByUserCache',
                        value: isLikedByUserCache,
                      });

                      console.log('isLikedByUserCache', isLikedByUserCache);

                      segment.track('Tag Liked', {
                        platform: Platform.OS,
                        id: item.id,
                      });
                    }
                  }}
                  onUncheck={() => {
                    const isLoggedIn =
                      variables.AUTH_TOKEN !== null &&
                      variables.AUTH_TOKEN !== '';

                    if (isLoggedIn) {
                      likesCache[item.id] = Math.max(
                        likesCache[item.id] - 1,
                        0
                      );
                      isLikedByUserCache[item.id] = false;
                      setVariables({ key: 'likesCache', value: likesCache });
                      setVariables({
                        key: 'isLikedByUserCache',
                        value: isLikedByUserCache,
                      });

                      segment.track('Tag Unliked', {
                        platform: Platform.OS,
                        id: item.id,
                      });
                    }
                  }}
                  onPress={newCheckboxValue => {
                    const handler = async () => {
                      try {
                        const isLoggedIn =
                          variables.AUTH_TOKEN !== null &&
                          variables.AUTH_TOKEN !== '';

                        if (!isLoggedIn) {
                          // setVariables({
                          //   key: 'showLoginModal',
                          //   value: true,
                          // });
                          navigation.navigate('LoginScreen');
                          return;
                        }

                        setIsLiking(true);
                        const likeResponse = await fetch(
                          `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/likes`,
                          {
                            method: 'POST',
                            body: JSON.stringify({
                              tag_id: item?.id,
                            }),
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: variables.AUTH_TOKEN,
                            },
                          }
                        );
                        const { status } = await likeResponse.json();

                        setIsLiking(false);
                      } catch (error) {
                        console.error(error);
                      }
                    };
                    handler();
                  }}
                  uncheckedIcon={'FontAwesome/heart-o'}
                  checkedIcon={'FontAwesome/heart'}
                  size={19}
                  uncheckedColor={theme.colors.text.strong}
                  color={theme.colors.text.strong}
                  status={isLikedByUserCache[item.id]} //liked
                />
              )}

              {likesCache[item.id] ? (
                <Text style={styles(theme).countText}>
                  {likesCache[item.id]}
                </Text>
              ) : null}
            </View>
            <View style={styles(theme).shareView}>
              {isSharing ? (
                <ActivityIndicator
                  size="small"
                  color={theme.colors.text.strong}
                />
              ) : (
                <Checkbox
                  onCheck={() => {}}
                  onUncheck={() => {}}
                  onPress={newCheckboxValue => {
                    const handler = async () => {
                      try {
                        const isLoggedIn =
                          variables.AUTH_TOKEN !== null &&
                          variables.AUTH_TOKEN !== '';

                        if (!isLoggedIn) {
                          // setVariables({
                          //   key: 'showLoginModal',
                          //   value: true,
                          // });
                          navigation.navigate('LoginScreen');
                          return;
                        }

                        if (Platform.OS === 'web') {
                          showDownloadModal && showDownloadModal();
                          return;
                        }

                        setIsSharing(true);

                        const shareResponse = await fetch(
                          `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/share_details`,
                          {
                            method: 'POST',
                            body: JSON.stringify({
                              tags_id: item?.id,
                            }),
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: variables.AUTH_TOKEN,
                            },
                          }
                        );
                        const { status } = await shareResponse.json();

                        setIsSharing(false);

                        if (status.indexOf('Success') !== -1) {
                          sharesCache[item.id] =
                            (sharesCache[item.id] || 0) + 1;
                          setVariables({
                            key: 'sharesCache',
                            value: sharesCache,
                          });

                          segment.track('Tag Shared', {
                            platform: Platform.OS,
                            id: item.id,
                          });

                          await openShareUtil(
                            `Check out what I found on Montage https://searchmontage.com/tag/${item?.id}`
                          );
                        }
                      } catch (error) {
                        console.error(error);
                      }
                    };
                    handler();
                  }}
                  uncheckedIcon={'MaterialCommunityIcons/share-outline'}
                  checkedIcon={'MaterialCommunityIcons/share'}
                  size={19}
                  uncheckedColor={theme.colors.text.strong}
                  color={theme.colors.text.strong}
                />
              )}

              {sharesCache[item.id] ? (
                <Text style={styles(theme).countText}>
                  {sharesCache[item.id]}
                </Text>
              ) : null}
            </View>
          </View>
        )}

        <View
          style={{
            position: 'absolute',
            top: 20,
            left: 20,
            background: 'black',
            display: 'none',
          }}
        >
          <Text style={{ color: 'white' }}>{item?.id}</Text>
        </View>
      </View>
    </>
  );
};

const AdView = ({ item, theme, cardWidth }) => {
  const segment = useAnalytics();

  return (
    <Pressable
      onPress={async () => {
        segment.track('Ad Clicked', {
          platform: Platform.OS,
          id: item.ads_id,
          url: item?.link,
        });

        await WebBrowser.openBrowserAsync(item?.link);
      }}
    >
      <View
        style={{
          ...styles(theme).cardHeader,
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          minHeight:
            item.ad_attachment.type === 'video'
              ? 350
              : (cardWidth * item?.ad_attachment?.meta?.height) /
                  item?.ad_attachment?.meta?.width || 175,
        }}
      >
        {item.ad_attachment.type === 'image' ? (
          <ExpoImage
            theme={theme}
            style={{
              borderRadius: 4,
            }}
            cardWidth={cardWidth}
            cardHeight={
              (cardWidth * item?.ad_attachment.meta?.height) /
                item?.ad_attachment?.meta?.width || 175
            }
            url={item?.ad_attachment?.url}
          />
        ) : (
          <VideoPlayer
            isLooping={true}
            isMuted={true}
            posterResizeMode={'cover'}
            rate={1}
            resizeMode={'cover'}
            shouldPlay={true}
            source={{
              uri: item?.ad_attachment?.url,
            }}
            style={StyleSheet.compose(
              GlobalStyles.VideoPlayerStyles(theme)['Video'],
              {
                height: '100%',
                width: '100%',
              }
            )}
            useNativeControls={false}
            volume={0}
          />
        )}
      </View>
    </Pressable>
  );
};

const styles = theme =>
  StyleSheet.create({
    container: {
      alignContent: 'flex-start',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: palettes.App['CardBorder'],
      borderRadius: 5,
      borderWidth: 1,
      marginBottom: 8,
      marginLeft: 4,
      marginRight: 4,
      paddingBottom: 8,
      overflow: 'hidden',
      height: Platform.OS === 'web' ? '100%' : undefined,
    },
    adContainer: {
      // borderColor: palettes.App['Border Color'],
      paddingBottom: 0,
      marginBottom: Platform.OS === 'web' ? 0 : 8,
    },
    notValidCard: {
      borderColor: palettes.App['Custom Color_11'],
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5,
    },
    errorContainer: {
      backgroundColor: palettes.App['Custom Color_11'],
      paddingTop: 3,
      paddingBottom: 3,
    },
    errorText: {
      color: palettes.App['White'],
      textAlign: 'center',
    },
    cardHeader: {
      alignContent: 'flex-start',
      alignItems: 'center',
      alignSelf: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderRadius: 5,
      flex: 1,
      flexBasis: 1,
      flexGrow: 1,
      justifyContent: 'flex-end',
      // minHeight: 175,
      height: '100%',
      width: '100%',
    },
    labelContainer: { flex: 1, padding: 4 },
    titleLabel: {
      color: theme.colors.text.strong,
      fontFamily: 'Poppins_400Regular',
      fontSize: 11,
    },
    descriptionLabel: {
      color: theme.colors.text.strong,
      fontFamily: 'Poppins_400Regular',
    },
    actionMenuContainer: {
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'row',
      height: 30,
      justifyContent: 'space-between',
      paddingBottom: 6,
      paddingTop: 6,
    },
    favoriteView: {
      alignItems: 'center',
      flex: 1,
      flexBasis: 1,
      flexDirection: 'row',
      justifyContent: 'center',
    },
    shareView: {
      alignItems: 'center',
      flex: 1,
      flexBasis: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      borderLeftWidth: 1,
      borderColor: palettes.App['CardBorder'],
    },

    countText: {
      color: theme.colors.text.strong,
      fontSize: 11,
      marginLeft: 8,
    },
  });
